html {
    font-size: 100%;
    font-family: 'Whitney-book';
}

body {
    margin: 0;
}

/* .gm-style-iw  button {
    display: none !important;
}  */



@font-face {
    font-family: 'Whitney-semibold';
    src: url('./Theme/Fonts/Whitney/Semibold/Whitney-Semibold.woff2') format('woff2'),
        url('./Theme/Fonts/Whitney/Semibold/Whitney-Semibold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Whitney-medium';
    src: url('./Theme/Fonts/Whitney/Medium/Whitney-Medium.woff2') format('woff2'),
        url('./Theme/Fonts/Whitney/Medium/Whitney-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Whitney-book';
    src: url('./Theme/Fonts/Whitney/Book/Whitney-Book.woff2') format('woff2'),
        url('./Theme/Fonts/Whitney/Book/Whitney-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Mercury-text-g2';
    src: url('./Theme/Fonts/Mercury/Roman/MercuryTextG2-Roman.woff2') format('woff2'),
    url('./Theme/Fonts/Mercury/Roman/MercuryTextG2-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


